import React from "react";
import { Footer } from "../utility-components/footer";
import { Navbar } from "../utility-components/navbar";
// import "../component-style/services.css";
export const Service = () => {
  return (
    <main>
      <Navbar />
      <div className="service-info">
        <div id="service-info-inner-container">
          <h1>
            <label htmlFor="">we</label> <br />
            Empower, <br />
            Transform, <br />
            Construct...
          </h1>
          <p>
            We empower our clients, partners and collaborations to transform the
            diagnostic industry <br />
            through our comprehensive range of services. Our team of experts
            possess a wealth of <br />
            experience in product development and are dedicated to leveraging
            the latest advancements in <br /> microfluidics, artificial
            intelligence, and machine vision to create disruptive solutions for
            our <br /> clients. As a  single-source technology partner, we offer
            scalable capabilities and a seamless <br /> experience to bring your
            vision to reality.
            <br />
            <br />
            Life’s too short to be confined. That’s why we thrive in conjunction
            with others. Our B2B <br /> collaborations have revolutionised the
            industry, pooling resources and innovating in ways that <br />{" "}
            exceed even the wildest imaginations. <br />
            We’ve formed dynamic Joint enterprises that bring the best minds of
            medicine and tech <br /> together. From masterminds of AI to the
            experts in  scientific research, we’ve joined hands to <br /> break
            the mould.
          </p>
        </div>
      </div>
      <div className="service-images">
        <div className="service-image-container">
          <div className="img"></div>
          <div className="img"></div>

          <div className="img"></div>

          <div className="img"></div>

          <div className="img"></div>

          <div className="img"></div>
        </div>
      </div>

      <Footer />
    </main>
  );
};
