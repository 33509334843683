import React, { useState, useEffect } from "react";
import "../component-style/blockingOverlay.css";
import notAllowedImg from "../assets/construction.svg";
export const BlockingOverlay = () => {
  const [shouldBlock, setShouldBlock] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      if (screenWidth <= 480 && screenHeight < 950) {
        setShouldBlock(false);
        localStorage.removeItem("shouldBlock");
      } else if (screenWidth < 1420 || screenHeight < 900) {
        setShouldBlock(true);
        localStorage.setItem("shouldBlock", "true");
      } else {
        setShouldBlock(false);
        localStorage.removeItem("shouldBlock");
      }
    };
    const shouldBlockStored = localStorage.getItem("shouldBlock");
    if (shouldBlockStored) {
      setShouldBlock(true);
    }

    // handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return shouldBlock ? (
    // <div style={{width:"100px", height:"100px",backgroundColor:"green"}}>BlockingOverlay</div>
    <div className="main_container">
      <div className="sub_contain">
        <img src={notAllowedImg} />
      </div>
    </div>
  ) : null;
};
