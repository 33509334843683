import React from "react";
import "../component-style/footer.css";
import sciverseLogo from "../assets/5ec609e08f0fcf0ffb56da694c78dc9b (1) 1.svg";
import phone from "../assets/footer_phone.svg";
import gmail from "../assets/footer_gmail.svg";
import linkedin from "../assets/footer_linkedin.svg";
export const Footer = () => {


  const navigateLink = (link) => {
    window.open(link,"_blank");
  }

  const handleNavigation = (event) => {
    const link = event.currentTarget.dataset.link;
    if(link){
      navigateLink(link)
    }
  }

  // const handleEmailClick = () => {
  //   // window.location = 'mailto:sciverse@sciverse.co.in';
  //   console.log("clicking gmail");
  //   const emailAddress = 'ajaysharma200240@gmail.com';
  //   const mailToLink = `mailto:${emailAddress}?bcc=${encodeURIComponent(emailAddress)}`;
  //   window.location.href = mailToLink;
  // }


  const emailAddress = 'info@sciverse.co.in';
  const gmailWebURL = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(emailAddress)}`;


  return (
    <footer id="footer-container">
      <div className="footer-inner">
        <div className="footer-flex">
          <div className="footer-logo-container">
            <img src={sciverseLogo} alt="" />

            <label> MedTech Re-envisioned </label>
          </div>

          <div className="footer-logo-container">
            <div className="contactUs_Content">Contact Us</div>

            <div id="footer-contact-icon">
              <img className="contact-icons" src={phone} alt=""  data-link="https://www.google.com/maps/dir//V18,+Balewadi+High+St,+Baner,+Pune,+Maharashtra+411045/@18.5702549,73.6932122,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2b9da110470d9:0x4ca06fcb4436706c!2m2!1d73.7756139!2d18.5702728?entry=ttu"
               onClick={handleNavigation}
              
              />
    <a className="contact-icons gmail" href={gmailWebURL} target="_blank" rel="noopener noreferrer" >
    <img src={gmail} alt="" 
    // onClick={()=>handleEmailClick}
    />
    </a>
             
              <img className="contact-icons" src={linkedin} alt="" data-link="https://www.linkedin.com/company/sciverse/mycompany/"
               onClick={handleNavigation}
               />
            </div>
          </div>
        </div>
      </div>

      <div className="footer-inner-2">
        <div className="footer_last_content">
     <span className="spn_1">Copyright 2024 Sciverse Solutions Private Limited. </span>  <span className="spn_2">        Address: #307, Vasudha-V18, Balewadi High Street, Pune (MH) - 411045</span>

      </div></div>
    </footer>
  );
};
