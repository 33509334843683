import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/home";
import { Service } from "./pages/service";
import { Products } from "./pages/products";
import { JobApplication } from "./pages/jobapplication";
import { ContactUs } from "./pages/contact";
import { Career } from "./pages/career";
import { BlockingOverlay } from "./pages/BlockingOverlay";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/service" element={<Service />} />

        <Route path="/products" element={<Products />} />

        <Route path="/jobapplication" element={<JobApplication />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/career" element={<Career />} />
      </Routes>
      <BlockingOverlay />
    </>
  );
}

export default App;
