import React from "react";
import { useState } from "react";
import "../component-style/career.css";
import { Footer } from "../utility-components/footer";
import { Navbar } from "../utility-components/navbar";
import { OpeningCard } from "../utility-components/OpeningCard";
import jobs from "../assets/jobs";
export const Career = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  let handleNavToggle = (value) => {
    setSidebarToggle(value);
    console.log(sidebarToggle);
  };
  return (
    <main>
      <Navbar sidebarToggle={sidebarToggle} handleNavToggle={handleNavToggle} />
      <div
        className="career-container"
        onClick={() => {
          handleNavToggle(false);
        }}
      >
        <div className="career-main-container">
          <h1 className="career-heading">
            Join our team of super- talented and fun individuals!
          </h1>
          <p className="career-paragraph">
            Our philosophy is simple - At Sciverse, we believe in hiring
            committed and responsible individuals, empowering them to learn by
            doing, and unleashing their potential to drive transformative change
            in healthcare.
          </p>
          {jobs.map((job, index) => (
            <OpeningCard key={index} job={job} />
          ))}
        </div>
      </div>
      <Footer />
    </main>
  );
};
