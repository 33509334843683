import React from "react";
import { useState } from "react";
import axios from "axios";
import "../component-style/contact.css";
import "../component-style/contactMobile.css";
import "../component-style/contactTab.css";
// import "../component-style/career.css";
import { Footer } from "../utility-components/footer";
import { Navbar } from "../utility-components/navbar";
// import stock from "../assets/34b709679c57c0abde11c3036642bd1b-removebg-preview(1).png";
import stock from "../assets/Group_42-transformed 1 (1).svg";
import location from "../assets/location.svg";
import timer from "../assets/timer.svg";
import call from "../assets/call.svg";
import mail from "../assets/mail.svg";
// import
export const ContactUs = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  let handleNavToggle = (value) => {
    setSidebarToggle(value);
    console.log(sidebarToggle);
  };
  const [textmessage, setTextMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });
  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    // console.log(formData);
    // formData.textmessage = formData.name;
  };

  const [messageSent, setmessageSent] = useState(false);
  async function sendPostRequest() {
    console.log(formData);

    try {
      const response = await axios.post(
        "http://98.70.9.35:8085/contactus",
        formData
      );
      const { message, status } = response.data;
      console.log("Message:", message);
      console.log("Status:", status);
      if (status == 200) {
        setTextMessage("Message Sent");
        setmessageSent(true);
        setFormData({
          name: "",
          email: "",
          number: "",
          message: "",
        });
      }
    } catch (error) {
      setTextMessage("Message failed. Please try again later.");
      console.error("There was an error making the POST request:", error);
    }
  }
  const onSubmitHandler = (event) => {
    for (const [key, value] of Object.entries(formData)) {
      if (!value && key != "number" && key != "message") {
        console.log(key);
        setTextMessage(`Please fill ${key} field`);
        break;
      } else {
        setTextMessage("");
        sendPostRequest();
      }
    }
    event.preventDefault();
    console.log(formData);
  };

  return (
    <main className="contactus">
      <Navbar sidebarToggle={sidebarToggle} handleNavToggle={handleNavToggle} />
      <div
        className="contactus-container"
        onClick={() => {
          handleNavToggle(false);
        }}
      >
        <div className="contactus-info">
          <h1>Let's Connect.</h1>
          <h3>Thank you for stopping by.</h3>
          <h5>We simply would love to know what you think.</h5>
          <p>So, please don't be shy to reach out with feedback,</p>
          <p>suggestions, a new idea to work on, or just a simple 'Hi'.</p>
        </div>
        <div className="contact-form-container">
          <p>Please fill the form below or find us on social</p>
          <p>media, or maybe get in touch via email...</p>
          <form className="contact-form" action="" onSubmit={onSubmitHandler}>
            <label htmlFor="name">
              What is your name? <sup>*</sup>
            </label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={onChangeHandler}
              value={formData.name}
            />
            <label htmlFor="">
              Where can we write you an e-mail at?<sup>*</sup>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              onChange={onChangeHandler}
              value={formData.email}
            />
            <label htmlFor="">What phone number can we reach you on?</label>
            <input
              type="tel"
              name="number"
              id="number"
              onChange={onChangeHandler}
              value={formData.number}
            />
            <label htmlFor="">We always read... Write us a message.</label>
            <textarea
              type="text"
              className="form-message"
              name="message"
              id="message"
              onChange={onChangeHandler}
              value={formData.message}
            />
            <div>
              {textmessage ? <p>{textmessage}</p> : <p>{textmessage}</p>}
            </div>
            <button className="contact-form-button">Send Message</button>
          </form>
        </div>
      </div>

      <div className="contactus-image">
        <img src={stock} alt="stock" />
      </div>

      <div
        className="contact-company-info"
        onClick={() => {
          handleNavToggle(false);
        }}
      >
        <div className="info-container-1">
          <div className="info">
            <img src={timer} alt="time" />
            <p>09:30 - 18:30</p>
          </div>
          <div className="info">
            <img src={location} alt="loc" />
            <p>
              <a href="https://www.google.com/maps/dir//V18,+Balewadi+High+St,+Baner,+Pune,+Maharashtra+411045/@18.5702549,73.6932122,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2b9da110470d9:0x4ca06fcb4436706c!2m2!1d73.7756139!2d18.5702728?entry=ttu">
                307, V-18, Balewadi High Street, Pune-411045
              </a>
            </p>
          </div>
        </div>
        <div className="info-container">
          <div className="info">
            <img src={mail} alt="mail" />
            <p>
              <a
                href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;to=info%40sciverse.co.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@sciverse.co.in
              </a>
            </p>
          </div>
          <div className="info">
            <img src={call} alt="tel" />
            <p>
              <a href="tel:+918240401969">+918240401969</a>
            </p>
          </div>
        </div>
      </div>
      <Footer
        onClick={() => {
          handleNavToggle(false);
        }}
      />
    </main>
  );
};
