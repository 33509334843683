import React, { useEffect, useState, useRef } from "react";
import "../component-style/home.css";
import "../component-style/homeMobile.css";
import "../component-style/service.css";
import SciverseLogo from "../assets/bw.svg";
import two_ellipse from "../assets/two_ellipse.svg";
import { Footer } from "../utility-components/footer";
import { Navbar } from "../utility-components/navbar";
import groupPic1 from "../assets/groupPIc.jpeg";
import sciverseOffice from "../assets/sciverse_office.png";
import { MultiColorRow } from "../utility-components/MultiColorRow";
import illustrator1 from "../assets/Illustrator_1.svg";
import illustrator2 from "../assets/Illustrator_2.svg";
import illustrator3 from "../assets/Illustrator_3.svg";
import illustrator4 from "../assets/Illustrator_4.svg";
import illustrator5 from "../assets/Illustrator_5.svg";
import illustrator6 from "../assets/Illustrator_6.svg";
import illustrator1_1 from "../assets/Illustrator_1_1.png";
import illustrator1_2 from "../assets/Illustrator_2_2.png";
import illustrator1_3 from "../assets/Illustrator_3_3.png";
import illustrator1_4 from "../assets/Illustrator_4_4.png";
import illustrator1_5 from "../assets/Illustrator_5_5.png";
import illustrator1_6 from "../assets/Illustrator_6_6.png";
import infinite from "../assets/infinite.svg";

import MyLabIcon from "../assets/mylabIcon.png";
const navigateLink = (link) => {
  window.open(link, "_blank");
};
export const Home = () => {
  const [isImgHovered, setIsImgHovered] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]); // Initial state for each image

  // Arrays to hold image captions and descriptions
  const imageCaptions = [
    "Epic Innovations",
    "B2B Solutions",
    "AI Mastery",
    "Skillful Team",
    "Opportunities",
    "Futuristic Tour",
  ];

  const imageDescriptions = [
    "Our technology aids us in actualizing Smart, dynamic, life-saving products.",
    "We are reinventing medical devices and products through pathbreaking alliances.",
    "We harness outstanding AI capabilities for various medical and research solutions.",
    "We’re proud of our diverse team that breaks stereotypes everyday.",
    "Make your career, interesting, challenging and ever-growing.",
    "Take a look into the future fueled by fascinating med-tech progress.",
  ];

  const containerRef = useRef(null);
  const aboutSmallRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [background, setBackground] = useState(
    "linear-gradient(to bottom, #bbd8f5, #cee3f7)"
  );

  useEffect(() => {
    const handleScroll = () => {
      const container = document.getElementById("container");
      if (container) {
        const containerTop = Math.ceil(container.getBoundingClientRect().top);

        if (
          containerTop == -1519 ||
          containerTop == -1517 ||
          containerTop == -1515 ||
          containerTop == -1516 ||
          containerTop == -1518 ||
          containerTop == -1520
        ) {
          setBackground(
            "-webkit-linear-gradient(top, #002b43 50%, #bbd8f5 50%)"
          );
        } else if (containerTop < -1520) {
          setBackground("#002b43");
        } else if (containerTop > -1515) {
          setBackground("linear-gradient(to bottom, #bbd8f5, #cee3f7)");
        } else {
          setBackground("linear-gradient(to bottom, #bbd8f5, #cee3f7)");
        }
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCardClick = (event) => {
    const link = event.currentTarget.dataset.link;
    if (link) {
      navigateLink(link);
    }
  };

  const handleButtonClick = () => {
    // if (containerRef.current) {
    //   const containerHeight = containerRef.current.clientHeight;
    //   const halfContainerOffset = containerHeight / 2;
    //   containerRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center", // Scroll to the center of the element
    //     inline: "nearest", // Scroll to the nearest edge of the element
    //   });
    // }
    if (window.innerWidth > 480 && containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center", // Scroll to the start of the element
        inline: "nearest",
      });
    } else if (window.innerWidth <= 480 && aboutSmallRef.current) {
      aboutSmallRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center", // Scroll to the start of the element
        inline: "nearest",
      });
    }
  };

  const wordsForRow1 = [
    "Innovation",
    "Collaboration",
    "Empathy",
    "Excellence",
    "Accessibility",
    "Focus and Impact",
    "Professionalism",
    "Curiosity",
    "Smart-Work",
    "User-centric",
    "Quality",
    "Value Addition",
    "Team-spirit",
    "Commitment",
    "Science and Technology",
    "Passion",
    "Ideation",
  ];
  const colorsForRow1 = [
    "#1770A2",
    "#3CA2DB",
    "#0A1572",
    "#A1D1FE",
    "#066ECD",
    "#0220BD",
    "50D4E7",
    "#7E8DDC",
    "#7EA6E1",
    "#0070C0",
    "#2A7CC7",
    "#004382",
    "#054FA5",
    "#1A7FDD",
    "27258E",
    "#3E75A8",
    "#CCD7FE",
  ];

  const wordsForRow2 = [
    "Professionalism",
    "Curosity",
    "Smart-work",
    "User-centric",
    "Quality",
    "Value Addition",
    "Team-spirit",
    "Commitment",
    "Science and Technology",
    "Passion",
    "Ideation",
    "Innovation",
    "Collaboration",
    "Empathy",
    "Excellence",
    "Accessibility",
    "Focus and Impact",
  ];
  const colorsForRow2 = [
    "#50D4E7",
    "#7E8DDC",
    "#88DBFF",
    "#00A9C0",
    "#2A7CC7",
    "#274663",
    "#054FA5",
    "#1A7FDD",
    "#27258E",
    "#3E75A8",
    "#CCD7FE",
    "#004382",
    "#3CA2DB",
    "#0A1572",
    "#96C0FE",
    "#066ECD",
    "#0220BD",
  ];

  const wordsForRow3 = [
    "Team-spirit",
    "Commitment",
    "Science and Technology",
    "Passion",
    "Ideation",
    "Innovation",
    "Collaboration",
    "Empathy",
    "Excellence",
    "Accessibility",
    "Focus and Impact",
    "Professionalism",
    "Curiosity",
    "Smart-work",
    "User-centric",
    "Quality",
    "Value addition",
  ];
  const colorsForRow3 = [
    "#054FA5",
    "#1A7FDD",
    "#27258E",
    "#3E75A8",
    "#CCD7FE",
    "#004382",
    "#3CA2DB",
    "#0A1572",
    "#96C0FE",
    "#066ECD",
    "#0220BD",
    "#50D4E7",
    "#7E8DDC",
    "#88DBFF",
    "#00A9C0",
    "#2A7CC7",
    "#274663",
  ];

  const [sidebarToggle, setSidebarToggle] = useState(false);
  let handleNavToggle = (value) => {
    setSidebarToggle(value);
  };

  return (
    <main>
      <Navbar sidebarToggle={sidebarToggle} handleNavToggle={handleNavToggle} />
      <div
        className="init"
        id="container"
        onClick={() => {
          handleNavToggle(false);
        }}
      >
        <div
          className="dummy-div"
          style={{ width: "100vw", height: "108px" }}
        ></div>
        <div className="welcome-text">
          <label>Welcome to</label>
          <img src={SciverseLogo} />
          <h1>where MedTech is Re-envisioned</h1>
          <button className="knw" onClick={handleButtonClick}>
            Know more
          </button>
        </div>

        <div
          className="spline-container"
          style={{ width: "99.16vw", height: "1200px", position: "relative" }}
        >
          <script
            type="module"
            src="https://unpkg.com/@splinetool/viewer@1.0.75/build/spline-viewer.js"
          ></script>
          <spline-viewer url="https://prod.spline.design/Nj1W1Vd4HDLXvfH6/scene.splinecode"></spline-viewer>
        </div>
        <div id="spline-container" style={{}}>
          <script
            type="module"
            src="https://unpkg.com/@splinetool/viewer@1.2.8/build/spline-viewer.js"
          ></script>
          <spline-viewer
            id="spline-viewer"
            url="https://prod.spline.design/uooNDt5FGJZxLENm/scene.splinecode"
          ></spline-viewer>
        </div>
      </div>

      {/* END OF 1ST HEADER */}
      <div
        className="about_container"
        onClick={() => {
          handleNavToggle(false);
        }}
      >
        <div className="about">
          <div className="about1">
            <div className="about1_container" ref={containerRef}>
              <div className="about1_content">
                <h3 className="title_content">About us</h3>
                Our name embodies our mission: to explore the boundless expanse
                of the Scientific Realm and harness its potential to transform
                healthcare. As a trailblazing MedTech company headquartered in
                India, we are committed to pushing the boundaries of innovation
                and reshaping the healthcare landscape.<br></br>
                <br></br>
                With a firm belief in healthcare as a fundamental human right,
                we strive to leverage cutting-edge technologies such as AI,
                microfluidics, and more to break down barriers and ensure
                equitable access to quality care for all. Our journey is fueled
                by a passion for discovery, a relentless pursuit of excellence,
                and a profound sense of compassion.
              </div>
            </div>
            <div className="about1_img">
              <div className="about1_image">
                <img className="about1_img_area" src={groupPic1}></img>
              </div>
            </div>
          </div>
          <div className="about1_mobile" ref={aboutSmallRef}>
            <div className="two_ellipse">
              <img src={two_ellipse} alt="" />
            </div>
            <h3 className="title_content">About us</h3>
            <p>
              <img className="about1_img_mobile" src={groupPic1}></img>
              At Sciverse, Our name embodies our mission: to explore the
              boundless expanse of the Scientific Realm and harness its
              potential to transform healthcare. As a trailblazing MedTech
              company headquartered in India, we are committed to pushing the
              boundaries of innovation and reshaping the healthcare landscape.
              With a firm belief in healthcare as a fundamental human right, we
              strive to leverage cutting-edge technologies such as AI,
              microfluidics, and more to break down barriers and ensure
              equitable access to quality care for all. Our journey is fueled by
              a passion for discovery, a relentless pursuit of excellence, and a
              profound sense of compassion.
            </p>
          </div>
          <div className="about2">
            <div className="about2_img">
              <div className="about2_image">
                <img className="about2_img_area" src={sciverseOffice}></img>
              </div>
            </div>
            <div className="about2_container">
              <div className="about2_content">
                <div className="ourMission">
                  <h3 className="title_content">Our Mission</h3>
                  <div className="content">
                    At Sciverse, we believe that healthcare should be
                    empowering, inclusive, and compassionate. Our mission is to
                    leverage the power of innovation to transform healthcare
                    delivery, improve patient outcomes, and enhance the overall
                    healthcare experience.
                  </div>
                </div>
                <div className="ourJourney">
                  <h3 className="title_content">Our Journey</h3>
                  <div className="content">
                    Our journey began with a bold vision and a commitment to
                    excellence. From our humble beginnings, we have grown into a
                    formidable force in the MedTech landscape, fueled by the
                    passion and dedication of our visionary founders and
                    talented team. Along the way, we have encountered
                    challenges, milestones, and moments of inspiration. Each
                    experience has shaped our journey and reinforced our belief
                    in the transformative potential of technology to drive
                    positive change in healthcare. <br></br>
                    <br></br>In our journey so far, we have had:
                  </div>
                </div>
                <div className="happy_clients_container">
                  <div className="happy_clients_sub_container">
                    <div className="happy_clients">
                      <span>10</span>
                      <br></br>Happy Clients
                    </div>
                    <div className="infinite">
                      {/* <span style={{ fontSize: "4.5vw", fontWeight: "600" }}>
                      &#8734;
                    </span> */}
                      <img
                        src={infinite}
                        alt="infinite"
                        className="infinite_icon"
                      />
                      <br></br>Sleepless nights
                    </div>
                    <div className="products_delivered">
                      <span>15</span>
                      <br></br>Product Delivered
                    </div>
                    <div className="cups_of_coffee">
                      <span>2000</span>
                      <br></br>Cups of Coffee
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about2_mobile">
            <h3 className="title_content_mobile">Our Mission</h3>
            <div className="content_mobile">
              <div className="about2_img_mobile_container">
                <img
                  className="about2_img_mobile"
                  src={sciverseOffice}
                  alt=""
                />
              </div>
              <p>
                At Sciverse, we believe that healthcare should be empowering,
                inclusive, and compassionate. Our mission is to leverage the
                power of innovation to transform healthcare delivery, improve
                patient outcomes, and enhance the overall healthcare experience.
              </p>
            </div>
            <div className="ourJourney">
              <h3 className="our_journey_title_content">Our Journey</h3>
              <p className="our_journey_content_mobile">
                Our journey began with a bold vision and a commitment to
                excellence. From our humble beginnings, we have grown into a
                formidable force in the MedTech landscape, fueled by the passion
                and dedication of our visionary founders and talented team.
                Along the way, we have encountered challenges, milestones, and
                moments of inspiration. Each experience has shaped our journey
                and reinforced our belief in the transformative potential of
                technology to drive positive change in healthcare. <br></br>
                <br></br>In our journey so far, we have had:
              </p>
            </div>
          </div>
          <div className="happy_clients_container_mobile">
            <div className="happy_clients_sub_container">
              <div className="happy_clients">
                <span>10</span>
                <br></br>Happy Clients
              </div>
              <div className="infinite">
                {/* <span className="infinite_icon">∞</span> */}
                <img src={infinite} alt="infinite" className="infinite_icon" />
                <p>Sleepless nights</p>
              </div>
              <div className="products_delivered">
                <span>15</span>
                <br></br>Product Delivered
              </div>
              <div className="cups_of_coffee">
                <span>2000</span>
                <br></br>Cups of Coffee
              </div>
            </div>
          </div>
        </div>
        <div id="value" className="values">
          <div className="values_1">Our Values</div>
          <div className="values_2">
            <MultiColorRow
              words={wordsForRow1}
              colors={colorsForRow1}
              isHovered={isHovered}
            />
            <MultiColorRow
              words={wordsForRow2}
              colors={colorsForRow2}
              isHovered={isHovered}
            />
            <MultiColorRow
              words={wordsForRow3}
              colors={colorsForRow3}
              isHovered={isHovered}
              // handleMouseEnter={handleMouseEnter}
              // handleMouseLeave={handleMouseLeave}
            />
          </div>
        </div>
      </div>
      <div
        id="colaborate"
        className="collaboration_container"
        onClick={() => {
          handleNavToggle(false);
        }}
      >
        <div className="collaboration_content">
          As we continue to push the boundaries of innovation and shape the
          future of healthcare, we invite <br></br>
          <span>
            you to join us on our journey. Together, we can make a meaningful
            difference in the lives of patients,
          </span>
          <br></br>
          <span className="collaboration_content_line3">
            caregivers, and communities across the globe.
          </span>
        </div>
        <div className="collaboration_content_mobile">
          <p>
            As we continue to push the boundaries of innovation and shape the
            future of healthcare, we invite you to join us on our journey.
            Together, we can make a meaningful difference in the lives of
            patients, caregivers, and communities across the globe.
          </p>
        </div>

        <button className="collaboration_btn">Collaborate</button>
      </div>
      <div
        className="service-images"
        onClick={() => {
          handleNavToggle(false);
        }}
      >
        <div className="service-image-container">
          {Array.from({ length: 6 }, (_, index) => (
            <div className="img" key={index}>
              <div className="img_illustrator">
                <img
                  className="illustrator_1"
                  src={
                    isImgHovered[index]
                      ? index === 0
                        ? illustrator1_1
                        : index === 1
                        ? illustrator1_2
                        : index === 2
                        ? illustrator1_3
                        : index === 3
                        ? illustrator1_4
                        : index === 4
                        ? illustrator1_5
                        : illustrator1_6
                      : index === 0
                      ? illustrator1
                      : index === 1
                      ? illustrator2
                      : index === 2
                      ? illustrator3
                      : index === 3
                      ? illustrator4
                      : index === 4
                      ? illustrator5
                      : illustrator6
                  }
                  onMouseEnter={() =>
                    setIsImgHovered((prevState) =>
                      prevState.map((value, i) => (i === index ? true : value))
                    )
                  }
                  onMouseLeave={() =>
                    setIsImgHovered((prevState) =>
                      prevState.map((value, i) => (i === index ? false : value))
                    )
                  }
                  alt={`Illustrator ${index + 1}`}
                />
              </div>
              <div className="img_content">
                <span>{imageCaptions[index]}</span>
                <br />
                {imageDescriptions[index]}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className="participate_container"
        onClick={() => {
          handleNavToggle(false);
        }}
      >
        <div className="participate_1">
          <div className="participate_1_content">
            If you are free and have an interest in all things science, could
            you please participate in a short survey for us.
          </div>
        </div>
        <div className="participate_2">
          <div className="participate_2_content">
            <button className="participate_2_btn">Participate</button>
          </div>
        </div>
      </div>

      <div
        className="testimonials-slider"
        onClick={() => {
          handleNavToggle(false);
        }}
      >
        <div
          className="testimonial-card"
          onClick={handleCardClick}
          data-link="https://timesofindia.indiatimes.com/city/goa/iit-goa-to-research-on-cost-effective-faster-detection-of-hiv-tuberculosis/amp_articleshow/96874917.cms"
        >
          <div className="testimonial_card_1">
            {" "}
            <span>The Indian Institute of</span>
            <br></br>
            <span>Technology (IIT), Goa,</span>
            <br></br>
            <span>has signed an MoU</span>
            <br></br>
            <span>with Sciverse</span>
            <br></br>
            <span>Solutions</span>
            <br></br>
            <span className="read_more">Read More</span>
          </div>
        </div>
        <div
          className="testimonial-card"
          onClick={handleCardClick}
          data-link="https://www.linkedin.com/posts/sciverse_sciverse-incorporationday-healthcarerevolution-activity-7084750027515924480-6RoC?utm_source=share&utm_medium=member_desktop"
        >
          <div className="testimonial_card_1" style={{ color: "#1770a2" }}>
            <span>One Year,</span>
            <br></br>
            <span>Many Missions -</span>
            <br></br>
            <span>Celebrating Sciverse's</span>
            <br></br>
            <span>Incorporation Day</span>
            <br></br>
            <span className="read_more">Read More</span>
          </div>
        </div>
        <div
          className="testimonial-card card_3"
          onClick={handleCardClick}
          data-link="https://www.biospectrumindia.com/news/92/22398/sciverse-solutions-signs-mou-with-iit-goa-to-build-lab-on-a-chip-ivd-microfluidics-platforms.html"
        >
          <div className="testimonial_card_1 ">
            {" "}
            <span>Sciverse Solutions signs</span>
            <br></br>
            <span>MoU with IIT Goa to build</span>
            <br></br>
            <span>Lab-on-a-Chip & IVD</span>
            <br></br>
            <span>microfluidics platforms</span>
            <br></br>
            <span className="read_more">Read More</span>
          </div>
        </div>
      </div>
      <div
        className="mylab_container"
        onClick={() => {
          handleNavToggle(false);
        }}
      >
        <div className="mylab_parent_container">
          <div className="mylab_content_container">
            <div className="mylab_content">
              We express our profound gratitude to our esteemed strategic
              investor and partner, Mylab Discovery Solutions Private Limited,
              for their unwavering support, invaluable guidance, and
              encouragement.
            </div>
          </div>
          <div className="mylab_icon_container">
            <div className="mylab_icon_content">
              <img className="mylab_icon" src={MyLabIcon}></img>
            </div>
          </div>
        </div>
      </div>
      <Footer
        onClick={() => {
          handleNavToggle(false);
        }}
      />
    </main>
  );
};
