import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../component-style/navbar.css";
import "../component-style/navbarMobile.css";
import SciverseLogo from "../assets/bw.svg";
import menu_open from "../assets/menu_open.svg";
import menu_close from "../assets/menu_close.svg";
import sidebar_image_1 from "../assets/sidebar_image_1.svg";
import sidebar_image_2 from "../assets/sidebar_image_2.svg";
import phone from "../assets/phone.svg";
import email from "../assets/email.svg";
import linkdin from "../assets/Linkedin.svg";

export const Navbar = ({ sidebarToggle, handleNavToggle }) => {
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };
  // const [sidebar_toggle, setSidebarToggle] = useState(false);

  return (
    <nav>
      <div className="navbar_main_container">
        <div
          className="navbar_container_1"
          onClick={() => {
            handleNavToggle(false);
          }}
        >
          <Link to="/" className="link" onClick={() => handleLinkClick(0)}>
            <img className="nav_logo" src={SciverseLogo} alt="" />
          </Link>
        </div>
        <div className="navbar_container_2">
          <div className={`navbar_item ${activeLink === 0 ? "active" : ""}`}>
            <Link to="/" className="link" onClick={() => handleLinkClick(0)}>
              Services
            </Link>
          </div>
          <div className={`navbar_item ${activeLink === 1 ? "active" : ""}`}>
            <Link to="/" className="link" onClick={() => handleLinkClick(1)}>
              AI
            </Link>
          </div>
          <div className={`navbar_item ${activeLink === 2 ? "active" : ""}`}>
            <Link to="/" className="link" onClick={() => handleLinkClick(2)}>
              Portfolio
            </Link>
          </div>
          <div className={`navbar_item ${activeLink === 3 ? "active" : ""}`}>
            <Link
              to="/career"
              className="link"
              onClick={() => handleLinkClick(3)}
            >
              Career
            </Link>
          </div>
          <div className={`navbar_item ${activeLink === 4 ? "active" : ""}`}>
            <Link
              to="/contactus"
              className="link"
              onClick={() => handleLinkClick(4)}
            >
              Contact Us
            </Link>
          </div>
        </div>
        {sidebarToggle ? (
          <div className="navbar_container_menu">
            <div className="menu_close">
              <img
                src={menu_close}
                alt="menu_close"
                onClick={() => {
                  handleNavToggle(false);
                }}
              />
            </div>
            <div className="sidebar_image_container_1">
              <img src={sidebar_image_1} alt="" />
            </div>

            <div className="menu">
              <div className="menu_line"></div>

              <Link to="/" className="menu_link">
                Services
              </Link>

              <div className="menu_line"></div>
              <Link to="/" className="menu_link">
                AI
              </Link>
              <div className="menu_line"></div>
              <Link to="/" className="menu_link">
                Portfolio
              </Link>
              <div className="menu_line"></div>
              <Link to="/career" className="menu_link">
                Careers
              </Link>
              <div className="menu_line"></div>
              <Link to="/contactus" className="menu_link">
                Contact us
              </Link>
              <div className="menu_line"></div>
            </div>
            <div className="sidebar_image_container_2">
              <img src={sidebar_image_2} alt="" />
            </div>
            <div className="social_links_container">
              <a href="tel:+918240401969">
                <img className="social_links_image" src={phone} alt="" />
              </a>
              <a
                href="https://mail.google.com/mail/?view=cm&fs=1&to=info%40sciverse.co.in"
                target="_blank"
              >
                <img className="social_links_image" src={email} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/sciverse/mycompany/"
                target="_blank"
              >
                <img className="social_links_image" src={linkdin} alt="" />
              </a>
            </div>
          </div>
        ) : (
          <div className="navbar_container_menu_button">
            <img
              className="menu_open"
              src={menu_open}
              alt="menu_open"
              onClick={() => {
                handleNavToggle(true);
                console.log(!sidebarToggle);
              }}
            />
          </div>
        )}
      </div>

      {/* 
<div className="nav-link">

<ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/blogs">Blogs</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>

</div> */}
    </nav>
  );
};
