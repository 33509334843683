import React from 'react'
import { Footer } from '../utility-components/footer'
import { Navbar } from '../utility-components/navbar'
import '../component-style/product.css'
export const Products = () => {
  return (
    <main >
        <Navbar/>
<div className='product-info'>

<div id='product-info-inner-container'  >

<h1>
    Our Products
</h1>




</div>
<div className="products">
<div className="product">
<div className="productImg"></div>
<div className="productdetails">
<h2>
SciDK:

</h2>
<h3>Measure your health, anywhere, anytime with just your smartphone</h3>

<p>



SciDK is a cutting-edge mobile health platform delivered as a comprehensive Software Development Kit (SDK) for a wide range of mobile devices and operating systems. It will empower users to easily and accurately measure a wide range of vital health parameters, including blood pressure, heart rate, heart rate variability, oxygen saturation, breathing rate, and our proprietary "wellness score", all using just their smartphone - no additional hardware required. 
As a telemedicine provider, discover how SciDK can assist you in monitoring your clients' wellness status, providing effective telemedicine with real-time vital sign monitoring, and understanding their needs. 

</p>
</div>


</div>


<div className="product">

<div className="productdetails">
<h2>
SciDK:

</h2>
<h3>Measure your health, anywhere, anytime with just your smartphone</h3>

<p>



SciDK is a cutting-edge mobile health platform delivered as a comprehensive Software Development Kit (SDK) for a wide range of mobile devices and operating systems. It will empower users to easily and accurately measure a wide range of vital health parameters, including blood pressure, heart rate, heart rate variability, oxygen saturation, breathing rate, and our proprietary "wellness score", all using just their smartphone - no additional hardware required. 
As a telemedicine provider, discover how SciDK can assist you in monitoring your clients' wellness status, providing effective telemedicine with real-time vital sign monitoring, and understanding their needs. 

</p>
</div>

<div className="productImg"></div>
</div>




</div>
</div>

<div className="scoll-img">
<h1>Coming Soon...</h1>
<div className="scoll-container">

<div className="scroll-card"></div>

<div className="scroll-card"></div>

<div className="scroll-card"></div>
{/* 
<div className="scroll-card"></div>

<div className="scroll-card"></div>

<div className="scroll-card"></div>

<div className="scroll-card"></div>

<div className="scroll-card"></div>

<div className="scroll-card"></div> */}


</div>


</div>

<Footer/>
    </main>
  )
}
