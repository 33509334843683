import React from "react";
import distance from "../assets/distance.svg";
import { Link } from "react-router-dom";
import time from "../assets/av_timer.svg";
import arrow from "../assets/arrow_outward.svg";
import "../component-style/openingcard.css";
import jobs from "../assets/jobs";
export const OpeningCard = ({ job }) => {
  const { field, position, description } = job;
  // console.log(field, position, description);
  return (
    <div className="opening-card">
      <div className="opening-container">
        <div className="job-content">
          <h3>{field}</h3>
          <h1>{position}</h1>
          <p>{description}</p>
          <div className="location-time">
            <p>
              <img src={distance} alt="distance" /> Balewadi High Street, Pune.
            </p>
            <p>
              <img src={time} alt="time" /> Full-time
            </p>
          </div>
        </div>
        {/* <div className="apply-button"><img src="" alt="arrow" /><p></p></div> */}
        {/* <Link to="/jobapplication">
          <button className="apply-button">
            Apply <img src={arrow} alt="" />
          </button>
        </Link> */}

        <button className="apply-button">
          <Link className="linkto" to="/jobapplication">
            Apply <img src={arrow} alt="" />
          </Link>
        </button>
      </div>
    </div>
  );
};
