import React from "react";
import "../component-style/jobapplication.css";
import { Footer } from "../utility-components/footer";
import { Navbar } from "../utility-components/navbar";
import { useState } from "react";
import axios from "axios";
import place_item from "../assets/place_item.svg";
import cross from "../assets/cross.png";
export const JobApplication = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  let handleNavToggle = (value) => {
    setSidebarToggle(value);
  };
  const [textmessage, setTextMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    dob: "",
    address: "",
    education: "",
    job: "",
    ctc: "",
    notice_period: "",
    url: "",
  });
  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const [messageSent, setmessageSent] = useState(false);
  async function sendPostRequest() {
    try {
      const response = await axios.post(
        "http://98.70.9.35:8085/career",
        formData
      );
      const { message, status } = response.data;
      if (status == 200) {
        setTextMessage("Application Sent");
        setmessageSent(true);
        setFormData({
          name: "",
          email: "",
          number: "",
          dob: "",
          address: "",
          education: "",
          job: "",
          ctc: "",
          notice_period: "",
          url: "",
        });
      }
    } catch (error) {
      setTextMessage("Message failed. Please try again later.");
      console.error("There was an error making the POST request:", error);
    }
  }
  const onSubmitHandler = (event) => {
    for (const [key, value] of Object.entries(formData)) {
      if (value == "") {
        setTextMessage(`Please fill ${key} field`);
        break;
      } else {
        setTextMessage("");
        sendPostRequest();
      }
    }
    event.preventDefault();
    console.log(formData);
  };
  const [fileName, setFileName] = useState("");
  // const [file, setFile] = useState(null);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : "");
  };

  // const handleCross = () => {
  //   setFileName("");
  // };
  return (
    <main>
      <Navbar sidebarToggle={sidebarToggle} handleNavToggle={handleNavToggle} />
      <div
        className="application-container"
        onClick={() => {
          handleNavToggle(false);
        }}
      >
        <form className="application-main-container" onSubmit={onSubmitHandler}>
          <h1 className="application-heading">Welcome to Sciverse</h1>
          <p className="application-paragraph">
            Please only apply if you love being part of the team. We highly
            value that in a candidate.
          </p>

          <p className="application-paragraph-2">
            Please either fill in the application form below or send an email
            with following details and more to{" "}
            <span>career@sciverse.co.in</span>
          </p>

          <div className="form-container">
            <div className="flex-input-container">
              <div className="input1">
                <label htmlFor="name">
                  Full name<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={onChangeHandler}
                  value={formData.name}
                />
              </div>

              <div className="input1">
                <label htmlFor="email">
                  Email address<sup>*</sup>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={onChangeHandler}
                  value={formData.email}
                />
              </div>
            </div>

            <div className="flex-input-container">
              <div className="input1">
                <label htmlFor="number">
                  Contact number<sup>*</sup>
                </label>
                <input
                  type="tel"
                  name="number"
                  id="number"
                  onChange={onChangeHandler}
                  value={formData.number}
                />
              </div>
              <div className="input1">
                <label htmlFor="address">
                  Place of Residence<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  onChange={onChangeHandler}
                  value={formData.address}
                />
              </div>
            </div>
            <div className="flex-input-container">
              <div className="input1">
                <label htmlFor="dob">
                  Date of Birth (DD/MM/YYYY)<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="dob"
                  id="dob"
                  onChange={onChangeHandler}
                  value={formData.dob}
                />
              </div>
              <div className="input1">
                <label htmlFor="education">
                  Most Recent Education<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="education"
                  id="education"
                  onChange={onChangeHandler}
                  value={formData.education}
                />
              </div>
            </div>
            <div className="flex-input-container">
              <div className="input1">
                <label htmlFor="job">
                  Most Recent Job Title<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="job"
                  id="job"
                  onChange={onChangeHandler}
                  value={formData.job}
                />
              </div>
              <div className="input1">
                <label htmlFor="ctc">
                  Most Recent CTC<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="ctc"
                  id="ctc"
                  onChange={onChangeHandler}
                  value={formData.ctc}
                />
              </div>
            </div>
            <div className="flex-input-container">
              <div className="input1">
                <label htmlFor="notice_period">
                  Notice period in days<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="notice_period"
                  id="notice_period"
                  onChange={onChangeHandler}
                  value={formData.notice_period}
                />
              </div>
              <div className="input1">
                <label htmlFor="url">
                  LinkedIn account URL<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="url"
                  id="url"
                  onChange={onChangeHandler}
                  value={formData.url}
                />
              </div>
            </div>

            <div className="flex-input-container">
              <div className="input1 file">
                <label htmlFor="file-upload" className="file-upload-label">
                  <p>Upload CV</p>
                  {fileName ? (
                    <div className="file-upload-label-div">
                      {/* <div className="cross" onClick={handleCross}>
                        <img src={cross} alt="cross" />
                      </div> */}
                      <p>{fileName}</p>
                    </div>
                  ) : (
                    <div className="file-upload-label-div">
                      <img
                        src={place_item}
                        htmlFor="file-upload"
                        alt="upload file"
                        className="file-upload-img"
                      ></img>
                    </div>
                  )}
                </label>
                <input
                  type="file"
                  className="file-upload"
                  name="file-upload"
                  id="file-upload"
                  onChange={handleFileChange}
                />

                <p className="input-para">
                  (Please make sure that your CV is up to date and has all your
                  qualifications and skills included)
                </p>
              </div>
              <div className="input1">
                <label htmlFor="">Write a cover letter </label>
                <textarea type="text" />

                <p className="input-para">
                  (Please include other links like Portfolio, GitHub, Figma,
                  etc. in the cover letter section)
                </p>
              </div>
            </div>
          </div>
          {textmessage ? <p>{textmessage}</p> : <p>{textmessage}</p>}
          <div className="btn-div">
            <button id="submit-btn">Submit Application</button>
          </div>
        </form>
      </div>
      <Footer />
    </main>
  );
};
