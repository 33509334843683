// jobs.js

const jobs = [
  {
    field: "Engineering",
    position: "Senior Flutter Developer",
    description: "We are looking for 4 year + experience",
  },
  {
    field: "Engineering",
    position: "Django & Flask Developer Intern",
    description:
      "Seeking skilled Django and Flask developer to design and implement web applications with proficiency in Python frameworks.",
  },
  {
    field: "Engineering",
    position: "React.js Intern",
    description:
      "Opportunity for React.js intern to learn and contribute to frontend development projects under mentorship.",
  },
  {
    field: "Engineering",
    position: "Data Scientist Intern",
    description:
      "Looking for a data scientist intern to explore, analyze, and derive insights from complex datasets, contributing to impactful projects with guidance from experienced mentors.",
  },
  {
    field: "Engineering",
    position: "Instrumentation Engineer",
    description:
      "Instrumentation Engineer with 3 years' experience sought to design and maintain precision instrumentation systems.",
  },
  {
    field: "Molecular Biology",
    position: "Biosensor Intern",
    description:
      "Seeking a biosensor intern to contribute to the development and testing of cutting-edge biosensing technologies, offering hands-on experience in a research-driven setting.",
  },
];

export default jobs;
