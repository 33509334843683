// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.career-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  width: 100%;
  /* height: 170vh; */
  background-image: linear-gradient(
    to bottom,
    #002b42,
    #2e4b62,
    #546d84,
    #7b92a7,
    #a4b8cc,
    #bbcbdd,
    #d1deee,
    #e8f1ff,
    #eff4ff,
    #f6f7ff,
    #fbfbff,
    #ffffff
  );
}

.career-main-container {
  /* border: 1px solid red; */
  width: 71.5vw;
  height: 100%;
  margin-top: 4.5vw;
  margin-bottom: 4.5vw;
}

.career-heading {
  width: 100%;
  color: white;
  line-height: 4.53vw;
  font-size: 4.15vw;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.career-paragraph {
  width: 100%;
  color: white;
  font-family: "Nunito";
  font-size: 2.1vw;
  line-height: 2.8vw;
}

.openings-container {
  width: 100%;
  border: 1px solid white;
}

@media (max-width: 480px) {
  .career-main-container {
    width: 82.32vw;
    margin-top: 7.2vw;
    margin-bottom: 7.2vw;
  }
  .career-heading {
    font-size: 8.2vw;
    line-height: 9.2vw;
    font-weight: 700;
  }
  .career-paragraph {
    font-size: 3.8vw;
    line-height: 5.3vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component-style/career.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;EACpB,WAAW;EACX,mBAAmB;EACnB;;;;;;;;;;;;;;GAcC;AACH;;AAEA;EACE,2BAA2B;EAC3B,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;EACjB,qCAAqC;EACrC,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE;IACE,cAAc;IACd,iBAAiB;IACjB,oBAAoB;EACtB;EACA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;EAClB;EACA;IACE,gBAAgB;IAChB,kBAAkB;EACpB;AACF","sourcesContent":[".career-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: end;\n  width: 100%;\n  /* height: 170vh; */\n  background-image: linear-gradient(\n    to bottom,\n    #002b42,\n    #2e4b62,\n    #546d84,\n    #7b92a7,\n    #a4b8cc,\n    #bbcbdd,\n    #d1deee,\n    #e8f1ff,\n    #eff4ff,\n    #f6f7ff,\n    #fbfbff,\n    #ffffff\n  );\n}\n\n.career-main-container {\n  /* border: 1px solid red; */\n  width: 71.5vw;\n  height: 100%;\n  margin-top: 4.5vw;\n  margin-bottom: 4.5vw;\n}\n\n.career-heading {\n  width: 100%;\n  color: white;\n  line-height: 4.53vw;\n  font-size: 4.15vw;\n  font-family: \"Montserrat\", sans-serif;\n  font-weight: 700;\n}\n\n.career-paragraph {\n  width: 100%;\n  color: white;\n  font-family: \"Nunito\";\n  font-size: 2.1vw;\n  line-height: 2.8vw;\n}\n\n.openings-container {\n  width: 100%;\n  border: 1px solid white;\n}\n\n@media (max-width: 480px) {\n  .career-main-container {\n    width: 82.32vw;\n    margin-top: 7.2vw;\n    margin-bottom: 7.2vw;\n  }\n  .career-heading {\n    font-size: 8.2vw;\n    line-height: 9.2vw;\n    font-weight: 700;\n  }\n  .career-paragraph {\n    font-size: 3.8vw;\n    line-height: 5.3vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
