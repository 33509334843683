import React, { useState, useEffect } from "react";

export const MultiColorRow = ({
  words,
  colors,
  isHovered,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const [animationRestarted, setAnimationRestarted] = useState(false);
  const rowStyle = {
    // marginTop: '3px',
    // Width:"150vw",

    fontSize: "24px",
    fontWeight: "400",
    // fontFamily:"Gotham",

    lineHeight: "29px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    // animation:isHovered ? 'none' : 'slide 60s linear infinite',
    animation:
      isHovered || animationRestarted ? "none" : "slide 120s linear infinite",
  };
  useEffect(() => {
    let timer;
    if (!isHovered && !animationRestarted) {
      timer = setTimeout(() => {
        setAnimationRestarted(true);
        setTimeout(() => {
          setAnimationRestarted(false); // Reset animationRestarted after 40 seconds
        }, 100); // Small delay before resetting to avoid immediate restart
      }, 42000); // 40 seconds in milliseconds
    }
    return () => clearTimeout(timer);
  }, [isHovered, animationRestarted]);

  return (
    <>
      <div
        style={rowStyle}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
      >
        {words.map((word, index) => (
          <span
            key={index}
            style={{
              fontWeight: "900",
              marginRight: "2vw",
              color: colors[index % colors.length],
            }}
          >
            {word}{" "}
          </span>
        ))}
      </div>
    </>
  );
};
